.slider {
  margin: 2px 0;
  position: relative;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  transform: translateZ(0);
  display: flex;
  overflow: hidden;
}

:global(.mainCarouselWrapper):hover .slickArrow:not(.disabled).slickPrev,
.mainCarouselWrapper:hover .slickArrow:not(.disabled).slickPrev {
  transform: translate(0, 0);
}

:global(.mainCarouselWrapper):hover .slickArrow:not(.disabled).slickNext,
.mainCarouselWrapper:hover .slickArrow:not(.disabled).slickNext {
  transform: translate(0, 0);
}

.mainCarouselWrapperItem {
  overflow: hidden;
  width: 100%;
  margin-block-end: 1.5rem;
}

.mainCarouselWrapperTitle {
  font-size: 1.25rem;
  margin-bottom: 0.75rem;
  padding: 0 clamp(1.5rem, 3cqw, 3rem);
}

.mainCarouselWrapperTitle.placeholder {
  display: block;
  height: 17px;
  width: 120px;
  margin: 5px clamp(1.5rem, 3cqw, 3rem) 0.75rem;
}

.slickArrow {
  position: absolute;
  display: none;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  padding: 0;
  border: none;
  outline: none;
  justify-content: center;
  align-items: center;
  width: clamp(1.5rem, 2cqw, 2rem);
  height: 100%;
  z-index: 1;
  visibility: visible;
  pointer-events: all;
  top: 0;
  transition: background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  background: var(--carousel-arrow-bg);
}

.slickArrow:hover {
  transition: background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  background: var(--carousel-arrow-hover-bg);
}

.slickArrow::before {
  content: '';
  display: none;
  font-size: 20px;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slickArrow::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
}

.slickPrev {
  left: 0;
  transform: translate(-100%, 0);
  transition: transform 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  will-change: transform;
}

.slickPrev::after {
  border-width: 7px 14px 7px 0;
  border-color: transparent var(--carousel-arrow-triangle-bg) transparent
    transparent;
  margin-inline-start: -2px;
}

.slickNext {
  right: 0;
  transform: translate(100%, 0);
  transition: transform 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  will-change: transform;
}

.slickNext::after {
  border-width: 7px 0 7px 14px;
  border-color: transparent transparent transparent
    var(--carousel-arrow-triangle-bg);
  margin-inline-end: -2px;
}

.slickSlide {
  padding: 0 7px;
}

.mainCarouselWrapperTileImage {
  background-size: cover;
  aspect-ratio: 1;
  position: relative;
  overflow: hidden;
  background-color: var(--carousel-loading-bg);
  filter: brightness(var(--carousel-image-filter));
  border-radius: 10%;
}

.mainCarouselWrapperTileImage:focus::after {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: var(--carousel-active-bg);
}

.mainCarouselAnchorWrapperTileImage {
  background-size: cover;
  border-radius: 10%;
  aspect-ratio: 1;
  position: relative;
  overflow: hidden;
  background-color: var(--carousel-loading-bg);
  filter: brightness(var(--carousel-image-filter));
  cursor: pointer;
  display: block;
}

@supports not (aspect-ratio: 1) {
  .mainCarouselWrapperTileImage {
    padding-bottom: 100%;
  }
}

.mainCarouselWrapperTileImage > picture {
  position: absolute;
}

.mainCarouselWrapperTileDesc {
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.85) 100%
  );
  border-radius: 0 0 10px 10px;
  /* We have to apply the border radius here too because Safari seems to have issues with absolute */
  color: white;
  font-family: var(--font-family-primary);
  font-size: 11px;
  font-weight: 500;
  overflow: hidden;
  padding: 10px 10px 9px;
  position: absolute;
  text-overflow: ellipsis;
  bottom: -1px;
  width: 100%;
}

.mainCarouselWrapperTileDesc img {
  border: 2px solid rgba(255, 255, 255, 0.8);
  border-radius: 100px;
  display: inline-block;
  margin-right: 5px;
  height: 24px;
  width: 24px;
  image-rendering: auto !important;
}

@media only screen and (min-width: 768px) {
  .slickArrow {
    display: flex;
  }

  .mainCarouselWrapperTileDesc {
    font-size: 12px;
  }

  .mainCarouselWrapperTileDesc img {
    height: 22px;
    width: 22px;
  }
}

.carouselContainerMain {
  padding-bottom: 0 !important;
}

.carouselContainerInner {
  height: 100%;
  position: relative;
  width: 100%;
  display: block;
  container-type: inline-size;
}

@container (min-width: 160px) {
  .carouselContainerInner .carouselMainTrack {
    font-size: 0.875rem;
  }
}

.carouselSpacer {
  padding-bottom: 100%;
}

.carouselArtistAvatar {
  background: white;
  border-radius: 50%;
  box-shadow: 0 0 0 3px rgba(255, 255, 255, 1);
  position: absolute;
  overflow: hidden;
  transform: translate(-50%, -50%);
}

.carouselArtistAvatar img {
  width: 100%;
}

.carouselArtistAvatar.main {
  top: 33%;
  left: 50%;
  height: 46%;
  width: 46%;
  z-index: 10;
}

.carouselArtistAvatar.left,
.carouselArtistAvatar.right {
  top: 48%;
  height: 35%;
  width: 35%;
  z-index: 5;
}

.carouselArtistAvatar.left {
  left: 26%;
}

.carouselArtistAvatar.right {
  right: 26%;
  transform: translate(50%, -50%);
}

.carouselMainTrack {
  bottom: 6%;
  left: 0;
  color: white;
  padding: 0 10px;
  position: absolute;
  text-align: center;
  width: 100%;
  line-height: 1.2;
  font-size: 0.625rem;
}

.carouselMainTrack strong,
.carouselMainTrack span {
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

@media (min-width: 2400px) {
  .mainCarouselWrapper .carouselMainTrack {
    font-size: 20px;
  }
}

.placeholderCarousel {
  pointer-events: none;
}

.mobileCarousel {
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
  display: block;
}

.mobileCarousel > .mobileSlide {
  scroll-margin-left: clamp(1.25rem, 2.5cqw, 2.5rem);
}

.mobileCarousel > .mobileSlide:first-child {
  margin-left: clamp(1.25rem, 2.5cqw, 2.5rem);
}

.mobileCarousel > .mobileSlide:last-child {
  margin-right: clamp(1.25rem, 2.5cqw, 2.5rem);
}

.mobileCarousel::-webkit-scrollbar {
  display: none;
}

/* Hide tile images until the polyfill kicks in */
@supports not (container-type: inline-size) {
  .mobileCarousel {
    opacity: 0;
  }
}

.mobileSlide {
  display: inline-block;
  padding: 0 clamp(0.25rem, 0.5cqw, 0.5rem);
  min-height: 1px;
}

.mobileCarousel .mainCarouselWrapperTileImage {
  display: block;
}

.tileImage {
  transition: opacity 0.1s cubic-bezier(0.4, 0, 0.2, 1);
}

/* Hidden container */
.containerHidden.mainCarouselWrapper {
  padding: 0;
}

.containerHidden .mainCarouselWrapperItem {
  padding: 0;
  border-radius: 0;
  background: none;
}

.containerHidden .mainCarouselWrapperTitle {
  padding-bottom: 0.5rem;
}

@media only screen and (min-width: 768px) {
  .containerHidden.mainCarouselWrapper {
    padding: 0;
    margin-block-end: 2rem;
  }

  .containerHidden .mainCarouselWrapperItem {
    padding: 0;
    border-radius: 0;
    background: none;
  }

  .containerHidden .mainCarouselWrapperTitle {
    padding-bottom: 1rem;
  }

  .containerHidden .slickSlider {
    padding: 0;
  }
}

.searchCarouselWrapper .mainCarouselWrapperItem {
  margin-block-end: 0;
}
